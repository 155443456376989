import React from 'react'
import { graphql } from 'gatsby'

import Root from '../components/Root'
import Meta from '../components/Meta'
import Blades from '../components/Blades'

export const query = graphql`
    query getPage($id: String) {
        page: datoCmsPage(id: { eq: $id }) {
            id
            meta {
                firstPublishedAt
            }
            seo {
                description
                title
                twitterCard
                image {
                    url(imgixParams: { w: "1200", h: "630", fit: "crop" })
                }
            }
            title
            blades {
                ... on DatoCmsCallToActionImage {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    id
                    heading
                    description
                    backgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }
                ... on DatoCmsCallToActionImageAlt {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    showButton
                    buttonLabel
                    isExternal
                    externalLink
                    link {
                        ... on DatoCmsPage {
                            model {
                                apiKey
                            }
                            slug
                        }
                        ... on DatoCmsCharacter {
                            model {
                                apiKey
                            }
                            slug
                        }
                    }
                    backgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }
                ... on DatoCmsCarouselSeasonReward {
                    scrollToBladeId
                    title
                    description
                    model {
                        apiKey
                    }
                    backgroundImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    rewards {
                        featuredImage {
                            gatsbyImageData
                        }
                        thumbnail {
                            gatsbyImageData
                        }
                        headingImage {
                            gatsbyImageData
                        }
                        descriptionTitle
                        description
                    }
                }
                ... on DatoCmsCarouselPreRegisterReward {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    backgroundImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    backgroundMobileImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    frontImage {
                        gatsbyImageData
                    }
                    foregroundImage {
                        gatsbyImageData
                    }
                    frontMobileImage {
                        gatsbyImageData
                    }
                    foregroundMobileImage {
                        gatsbyImageData
                    }
                    title
                    rewards {
                        featuredImage {
                            gatsbyImageData
                        }
                        thumbnail {
                            gatsbyImageData
                        }
                        # headingImage {
                        #   gatsbyImageData(placeholder: NONE)
                        # }
                        video {
                            video {
                                mp4Url
                            }
                        }
                        descriptionTitle
                        description
                        showSignUpButton
                    }
                }
                ... on DatoCmsCarouselCharacter {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    backgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                    showAll
                    characters {
                        name
                        description
                        position
                        classType {
                            name
                            colour {
                                hex
                                red
                                green
                                blue
                            }
                        }
                        comingSoon
                        logo {
                            gatsbyImageData(placeholder: NONE)
                        }
                        thumbnail {
                            gatsbyImageData
                        }
                        featuredImage {
                            gatsbyImageData
                        }
                    }
                }
                ... on DatoCmsCarouselFeaturedCharacter {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    showAll
                    characters {
                        name
                        slug
                        description
                        position
                        comingSoon
                        logo {
                            gatsbyImageData(placeholder: NONE)
                        }
                        thumbnail {
                            gatsbyImageData
                        }
                        featuredImage {
                            gatsbyImageData
                        }
                        featuredImageSmall: featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600", h: "600" }
                            )
                        }
                    }
                }
                ... on DatoCmsCarouselDownload {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    downloads {
                        title
                        file {
                            url
                            filename
                        }
                        thumbnail {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "300", h: "300", fit: "crop" }
                            )
                        }
                        tags
                    }
                    filterLabel
                    backgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }
                ... on DatoCmsCarouselArena {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    arenas {
                        name
                        description
                        featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: {
                                    w: "1020"
                                    h: "1080"
                                    fit: "crop"
                                }
                            )
                        }
                        mobileBackgroundImage: featuredImage {
                            fluid(
                                maxWidth: 600
                                maxHeight: 800
                                imgixParams: {
                                    maxW: 600
                                    maxH: 800
                                    fit: "crop"
                                }
                            ) {
                                ...GatsbyDatoCmsFluid
                            }
                        }
                        backgroundImage: featuredImage {
                            fluid(
                                maxWidth: 2800
                                maxHeight: 1400
                                imgixParams: {
                                    maxW: 2800
                                    maxH: 1400
                                    fit: "crop"
                                }
                            ) {
                                ...GatsbyDatoCmsFluid
                            }
                        }
                        informationNode {
                            image {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "520"
                                        h: "300"
                                        fit: "crop"
                                    }
                                )
                            }
                            heading
                            description
                            nodePosition
                            scrollToBladeId
                        }
                    }
                }
                ... on DatoCmsCarouselClassType {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    classTypes {
                        type: classType
                        description
                        icon {
                            url
                        }
                        # thumbnail {
                        #     gatsbyImageData(
                        #         placeholder: BLURRED
                        #         imgixParams: { w: "600", h: "350", fit: "crop" }
                        #     )
                        # }
                        video {
                            url
                        }
                        assets {
                            video {
                                mp4Url
                            }
                            thumbnail: gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600", h: "350", fit: "crop" }
                            )
                        }
                    }
                }
                ... on DatoCmsSeasonPassShowcase {
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    model {
                        apiKey
                    }
                    heading
                    seasonPassCards {
                        tag
                        image {
                            gatsbyImageData(placeholder: BLURRED)
                            focalPoint {
                                x
                                y
                            }
                        }
                        content
                        hasButton
                        buttonText
                        buttonLink
                    }
                }
                ... on DatoCmsColumnsThumbnail {
                    scrollToBladeId
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    model {
                        apiKey
                    }
                    heading
                    columns {
                        heading
                        description
                        image {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "865", h: "490", fit: "crop" }
                            )
                        }
                        video {
                            url
                        }
                        aurebesh {
                            url
                        }
                        aurebeshAnimation
                    }
                }
                ... on DatoCmsHeroVideo {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    video {
                        title
                        url
                        width
                        height
                        provider
                        providerUid
                        thumbnailUrl
                    }
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    hidePlayIcon
                    playIconText
                }
                ... on DatoCmsReleaseHeroVideo {
                    model {
                        apiKey
                    }
                    video {
                        title
                        url
                        width
                        height
                        provider
                        providerUid
                        thumbnailUrl
                    }
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    playIconText
                }
                ... on DatoCmsHeroSeason {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    startDate
                    hasButton
                    buttonExternalUrl
                    buttonLabel
                    buttonColor {
                        hex
                    }
                    buttonTextColor
                    endDate
                    newSeasonArrivalDate
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    showNewSeasonCountdown
                    centerImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
                ... on DatoCmsHeroText {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    leftImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    rightImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    small
                }
                ... on DatoCmsHeroNews {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    buttonLabel
                    posts {
                        id
                        originalId
                        slug
                        locale
                        title
                        position
                        excerpt
                        meta {
                            firstPublishedAt
                        }
                        tags
                        category {
                            categoryName
                        }
                        featuredImage {
                            fluid {
                                aspectRatio
                                base64
                                height
                                sizes
                                src
                                srcSet
                                width
                            }
                        }
                        characters {
                            id
                        }
                        thumbnail: featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600", h: "600", fit: "crop" }
                            )
                        }
                        thumbnailSmall: featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600", h: "400", fit: "crop" }
                            )
                        }
                    }
                }
                ... on DatoCmsHeroCharacter {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    character {
                        logo {
                            gatsbyImageData(placeholder: NONE)
                        }
                        featuredImage {
                            gatsbyImageData
                        }
                        animation {
                            url
                        }
                        animationFallbackImage {
                            gatsbyImageData
                        }
                    }
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
                ... on DatoCmsIncentive {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    subheading
                    milestones {
                        milestone
                        heading
                        thumbnail {
                            gatsbyImageData(placeholder: NONE)
                        }
                        lockedStatus
                        percentage
                    }
                    bottomImage {
                        gatsbyImageData(placeholder: NONE)
                    }
                    backgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                    shareHeading
                }
                ... on DatoCmsLatestNews {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    buttonLabel
                    backgroundImage {
                        gatsbyImageData
                    }
                }
                ... on DatoCmsFeaturedNews {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    posts {
                        id
                        originalId
                        slug
                        locale
                        title
                        position
                        meta {
                            firstPublishedAt
                        }
                        tags
                        category {
                            categoryName
                        }
                        featuredImage {
                            fluid {
                                aspectRatio
                                base64
                                height
                                sizes
                                src
                                srcSet
                                width
                            }
                        }
                        characters {
                            id
                        }
                        thumbnail: featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "400", h: "400", fit: "crop" }
                            )
                        }
                        thumbnailSmall: featuredImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "400", h: "200", fit: "crop" }
                            )
                        }
                    }
                }
                ... on DatoCmsLatestArenaNews {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                }
                ... on DatoCmsPanelImage {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    backgroundImage {
                        fluid(
                            imgixParams: {
                                w: "3000"
                                h: "1500"
                                fit: "crop"
                                q: 100
                            }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }
                ... on DatoCmsPanelBasicVideo {
                    scrollToBladeId
                    idToScrollTo
                    navigateToSamePage
                    model {
                        apiKey
                    }
                    heading
                    description
                    video {
                        title
                        url
                        width
                        height
                        provider
                        providerUid
                        thumbnailUrl
                    }
                    thumbnail {
                        gatsbyImageData
                    }
                    showButton
                    link {
                        slug
                    }
                    buttonLabel
                }
                ... on DatoCmsPanelVideo {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    video {
                        title
                        url
                        width
                        height
                        provider
                        providerUid
                        thumbnailUrl
                    }
                    thumbnail {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    showButton
                    link {
                        slug
                    }
                    buttonText
                    backgroundImage {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: {
                                w: "3000"
                                h: "1500"
                                fit: "crop"
                                q: 100
                            }
                        )
                        fluid(
                            imgixParams: {
                                w: "3000"
                                h: "1500"
                                fit: "crop"
                                q: 100
                            }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                    showDownloadOverlay
                    downloadHeading
                    downloadLink
                    downloadSubheading
                    submitLabel
                    privacyLabel
                    loadingMessage
                    successMessage
                    alreadyRegisteredMessage
                    mustOptInMessage
                    invalidEmailAddressMessage
                    genericErrorMessage
                    googlePlayLogo {
                        gatsbyImageData(
                            placeholder: BLURRED
                            # imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    googlePlayLink
                    appStoreLogo {
                        gatsbyImageData(
                            placeholder: BLURRED
                            # imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    appStoreLink
                    steamLogo {
                        gatsbyImageData(
                            placeholder: BLURRED
                            # imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    steamLink
                    nintendoLogo {
                        gatsbyImageData(
                            placeholder: BLURRED
                            # imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    nintendoLink
                }
                ... on DatoCmsPanelThumbnail {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    headingIcon {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: { w: "300", h: "300", fit: "fillmax" }
                        )
                    }
                    subheading
                    description
                    thumbnailSubheading
                    thumbnail {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: { w: "684", h: "249", fit: "crop" }
                        )
                    }
                    thumbnailHeading
                    thumbnailDescription
                }
                ... on DatoCmsPreregisterWithIncentive {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    title
                    description
                    formTitle
                    date
                    mediaItems {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    qrCodeText
                    qrCodeImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    googlePlayButtonImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    googlePlayButtonLink
                    iosButtonImage {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    iosButtonLink
                    platformIcon {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                    formTitle
                    submitLabel
                    privacyLabel
                    loadingMessage
                    successMessage
                    invalidMessage
                    alreadyRegisteredMessage
                    mustOptInMessage
                    invalidEmailAddressMessage
                    genericErrorMessage
                    rewardHeading
                    rewardDescription
                    nintendoSwitchExclusive {
                        name
                        description
                        mainImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: {
                                    h: "1200"
                                    w: "600"
                                    fit: "crop"
                                }
                            )
                        }
                        secondaryImage {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        focusTitle
                        focusImage {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                    rewardsBackgroundImage {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }
                ... on DatoCmsPlatformReward {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    secondaryDescription
                    backgroundImage {
                        fluid(
                            imgixParams: {
                                w: "3000"
                                h: "1500"
                                fit: "crop"
                                q: 100
                            }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                    rewards {
                        name
                        description
                        mainImage {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: {
                                    h: "1200"
                                    w: "600"
                                    fit: "crop"
                                }
                            )
                        }
                        secondaryImage {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        focusTitle
                        focusImage {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
                ... on DatoCmsSimplePreRegistration {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    submitLabel
                    privacyLabel

                    loadingMessage
                    successMessage
                    invalidMessage
                    alreadyRegisteredMessage
                    mustOptInMessage
                    invalidEmailAddressMessage

                    genericErrorMessage
                }
                ... on DatoCmsPreRegistration {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    contentCreatorLabel
                    yesLabel
                    noLabel
                    submitLabel
                    privacyLabel
                    referrerOptions
                    platformPreferenceOptions
                    genreOptions
                    frequencyOptions
                    loadingMessage
                    successMessage
                    invalidMessage
                    alreadyRegisteredMessage
                    mustOptInMessage
                    invalidEmailAddressMessage
                    invalidPlatformMessage
                    genericErrorMessage
                }
                ... on DatoCmsGridVideo {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    perPage
                    buttonLabel
                    videos {
                        heading
                        video {
                            title
                            url
                            width
                            height
                            provider
                            providerUid
                            thumbnailUrl
                        }
                        thumbnail {
                            gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600", h: "350", fit: "crop" }
                            )
                        }
                    }
                }
                ... on DatoCmsGridSocial {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                }
                ... on DatoCmsGridCharacter {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    perPage
                }
                ... on DatoCmsGridNews {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    perPage
                }
                ... on DatoCmsGridPerk {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    body
                    images {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: { w: "400", h: "300", fit: "crop" }
                        )
                        filename
                        url
                        title
                    }
                    background {
                        fluid(
                            imgixParams: { w: "2700", h: "1140", fit: "crop" }
                        ) {
                            aspectRatio
                            base64
                            height
                            sizes
                            src
                            srcSet
                            width
                        }
                    }
                }

                ... on DatoCmsGalleryMediaBlock {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    assets {
                        tags
                        image {
                            sm: gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "400" }
                            )
                            lg: gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600" }
                            )
                            wide: gatsbyImageData(
                                placeholder: BLURRED
                                imgixParams: { w: "600" }
                            )
                            full: fluid(
                                imgixParams: {
                                    w: "2700"
                                    h: "1140"
                                    fit: "crop"
                                }
                            ) {
                                aspectRatio
                                base64
                                height
                                sizes
                                src
                                srcSet
                                width
                            }
                            filename
                            url
                        }
                        description
                    }
                    filterLabel
                }

                ... on DatoCmsCharacterAbility {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    description
                    mobileBackgroundImage: backgroundImage {
                        fluid(
                            maxWidth: 600
                            maxHeight: 800
                            imgixParams: { maxW: 600, maxH: 800, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    backgroundImage {
                        fluid(
                            maxWidth: 2800
                            maxHeight: 1400
                            imgixParams: { maxW: 2800, maxH: 1400, fit: "crop" }
                        ) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                    character {
                        ultimate {
                            name
                            description
                            thumbnail {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "600"
                                        h: "350"
                                        fit: "crop"
                                    }
                                )
                            }
                            video {
                                video {
                                    mp4Url
                                }
                            }
                        }
                        abilities {
                            name
                            icon {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "300"
                                        h: "300"
                                        fit: "crop"
                                    }
                                )
                            }
                            thumbnail {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "600"
                                        h: "350"
                                        fit: "crop"
                                    }
                                )
                            }
                            video {
                                video {
                                    mp4Url
                                }
                            }
                            description
                        }
                        passiveAbilities {
                            name
                            icon {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "300"
                                        h: "300"
                                        fit: "crop"
                                    }
                                )
                            }
                            description
                        }
                    }
                }
                ... on DatoCmsCharacterCustomisation {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    character {
                        abilities {
                            name
                            icon {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "300"
                                        h: "300"
                                        fit: "crop"
                                    }
                                )
                            }
                            thumbnail {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "600"
                                        h: "350"
                                        fit: "crop"
                                    }
                                )
                            }
                            video {
                                video {
                                    mp4Url
                                }
                            }
                        }
                    }
                }
                ... on DatoCmsCharacterRelatedMediaShowcase {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    character {
                        abilities {
                            name
                            icon {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "300"
                                        h: "300"
                                        fit: "crop"
                                    }
                                )
                            }
                            thumbnail {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    imgixParams: {
                                        w: "600"
                                        h: "350"
                                        fit: "crop"
                                    }
                                )
                            }
                        }
                    }
                }

                ... on DatoCmsProgramOverview {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    tierHeading
                    requirementsHeading
                    benefitsHeading
                    tiers {
                        name
                        benefits
                        requirements
                    }
                    buttonHeading
                    buttonText
                    buttonLink {
                        ... on DatoCmsPage {
                            model {
                                apiKey
                            }
                            slug
                        }
                        ... on DatoCmsCharacter {
                            model {
                                apiKey
                            }
                            slug
                        }
                        ... on DatoCmsPost {
                            model {
                                apiKey
                            }
                            slug
                        }
                    }
                }

                ... on DatoCmsTextWithMediaBlock {
                    scrollToBladeId
                    model {
                        apiKey
                    }
                    heading
                    body
                    image {
                        gatsbyImageData(
                            placeholder: BLURRED
                            imgixParams: { w: "600", h: "350", fit: "crop" }
                        )
                    }
                    video {
                        video {
                            mp4Url
                        }
                    }
                }

                ... on DatoCmsFaq {
                    model {
                        apiKey
                    }
                    heading
                    buttonLabel
                    questions {
                        question
                        answer
                    }
                }
            }
        }
    }
`

const PageTemplate = ({
    data,
    pageContext: {
        recordTitle = null,
        globalConfig,
        locale,
        allPosts = [],
        allCharacters = [],
        classTypes = [],
        allArenas = [],
        allArenaNews = [],
    },
    ...props
}) => {
    const blades = (data?.page?.blades || []).filter((blade) => blade !== null)

    return (
        <Root {...{ globalConfig, locale, ...props }}>
            <Meta seo={{ recordTitle, ...data?.page?.seo }} />
            <Blades
                {...{
                    blades,
                    allPosts,
                    allCharacters,
                    classTypes,
                    allArenas,
                    allArenaNews,
                    locale,
                    globalConfig,
                }}
            />
        </Root>
    )
}

export default PageTemplate
